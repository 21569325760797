<template scoped>
  <div :style="{ height: '100%' }">
    <el-page-header
      :style="{ margin: '10px' }"
      @back="back"
      :content="getTitle"
    >
    </el-page-header>

    <el-form
      :style="{
        padding: '10px',
        margin: '10px',
        height: 'calc(100% - 80px)',
        background: '#ffffff',
      }"
      status-icon
      ref="detail"
      class="config"
      :model="template"
      label-width="120px"
      :rules="rules"
    >
      <el-alert
        v-if="success"
        :title="getStatusTitle"
        type="success"
        :description="getDescription"
        show-icon
      >
      </el-alert>

      <el-form-item label="Name" prop="name" :style="{ marginTop: '10px' }">
        <el-input v-model="template.name"></el-input>
      </el-form-item>

      <el-form-item
        label="Content"
        prop="content"
        :style="{ marginTop: '10px' }"
      >
        <el-input
          type="textarea"
          :rows="4"
          v-model="template.content"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="create" v-if="template.id.length == 0"
          >Create</el-button
        >
        <el-button type="primary" @click="update" v-if="template.id.length > 0"
          >Update</el-button
        >
        <el-button @click="back">Cancel</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    toPage: Function,
    endpoint: String,
    parameter: Object,
  },
  data: function () {
    return {
      template: {
        id: "",
        name: "",
        content: "",
      },
      success: false,
      rules: {
        name: [{ validator: this.validator_name, trigger: "blur" }],
        content: [{ validator: this.validator_content, trigger: "blur" }],
      },
    };
  },
  computed: {
    getDescription: function () {
      return `instanceId # ${this.template.id}`;
    },
    getStatusTitle: function () {
      if (this.template.id) {
        return "Update Success";
      } else {
        return "Create Success";
      }
    },
    getTitle: function () {
      if (this.template.id) {
        return "Edit Template";
      } else {
        return "Create Template";
      }
    },
  },
  created: function () {
    if (this.parameter) {
      this.template.id = this.parameter.templateId;

      var $this = this;

      fetch(`${this.endpoint}/api/Backend/Template/${this.template.id}`)
        .then(function (request) {
          return request.json();
        })
        .then(function (response) {
          if (response.statusCode == 200) {
            $this.template.name = response.result.name;
            $this.template.content = response.result.content;
          }
        });
    }
  },
  methods: {
    back: function () {
      this.toPage(
        "applications/user/j1/business/notification-hub/Template/NotificationHub-Template.vue"
      );
    },
    validator_name: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the name should not be empty"));
      } else {
        callback();
      }
    },
    validator_content: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the content should not be empty"));
      } else {
        callback();
      }
    },
    update: function () {
      var $this = this;
      this.$refs.detail.validate((valid) => {
        if (valid) {
          fetch(`${this.endpoint}/api/Backend/Template/${this.template.id}`, {
            method: "PUT",
            body: JSON.stringify({
              name: $this.template.name,
              content: $this.template.content,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then(function (request) {
              return request.json();
            })
            .then(function (response) {
              if (response.statusCode == 200) {
                $this.success = true;
              }
            });
        }
      });
    },
    create: function () {
      var $this = this;
      this.$refs.detail.validate((valid) => {
        if (valid) {
          fetch(`${this.endpoint}/api/Backend/Template`, {
            method: "POST",
            body: JSON.stringify({
              name: $this.template.name,
              content: $this.template.content,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then(function (request) {
              return request.json();
            })
            .then(function (response) {
              if (response.statusCode == 200) {
                $this.success = true;
                $this.template.id = response.result;
              }
            });
        }
      });
    },
  },
};
</script>
